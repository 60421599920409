import { NextRequest } from 'next/server'

import { experiments } from './experiments'
import { ExperimentItem, Variant, Variants_Enum } from './types'

function getVariantsWithScaledWeight(variants: Variant[], scale = 100) {
  const totalWeight = variants.reduce((acc, cur) => acc + cur.weight, 0)

  return variants.map(cur => {
    const originalWeight = cur.weight || 0
    return {
      ...cur,
      weight: (scale * originalWeight) / totalWeight,
    }
  })
}

export function determineVariantByWeight(
  variants: Variant[]
): Variants_Enum | undefined {
  const scale = 10000
  const variantsWithScaledWeight = getVariantsWithScaledWeight(variants, scale)
  if (!variantsWithScaledWeight.length) {
    return
  }

  let n = Math.floor(Math.random() * scale)

  return variantsWithScaledWeight.find(v => {
    if (v.weight >= n) {
      return true
    }
    n -= v.weight
    return false
  })?.value
}

export function getExperimentCookieName(
  exp: ExperimentItem
): string | undefined {
  if (!exp?.id) {
    return
  }
  return `_experiment.${exp.id.toLowerCase()}`
}

export function stringToValidEnumValue(value: string): Variants_Enum | null {
  if (typeof value !== 'string') {
    return null
  }
  return (
    Variants_Enum[value.toUpperCase() as keyof typeof Variants_Enum] || null
  )
}

export function generateCookieByVariant(
  request: NextRequest
): Array<{
  name: string
  value: string
}> {
  const exps = experiments.filter(e => e.setInitialValueInSSR)
  const cookies: Array<{
    name: string
    value: string
  }> = []

  for (const exp of exps) {
    const cookieName = getExperimentCookieName(exp)
    if (!cookieName) {
      continue
    }

    const cookieValue = request.cookies.get(cookieName)
    if (cookieValue && stringToValidEnumValue(cookieValue)) {
      continue
    }

    const variantValue = determineVariantByWeight(exp.variants)
    if (!variantValue) {
      continue
    }

    cookies.push({
      name: cookieName,
      value: variantValue,
    })
  }

  return cookies
}
